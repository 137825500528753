/* JQUERY FUNCTION */

function delaySpan(el, delay) {
  el.each(function () {
    $(this).children().children().each(function (a) {
      a++;
      setCssOrderDelay($(this), a, delay);
    })
  });
}

function findAncestor(el, cls) {
  while ((el = el.parentElement) && !el.classList.contains(cls)) ;
  return el;
}

function onElementHeightChange(elm, callback) {
  var lastHeight = elm.clientHeight;
  var newHeight;

  (function run() {
    newHeight = elm.clientHeight;
    if (lastHeight !== newHeight) callback();
    lastHeight = newHeight;

    if (elm.onElementHeightChangeTimer) {
      clearTimeout(elm.onElementHeightChangeTimer);
    }

    elm.onElementHeightChangeTimer = setTimeout(run, 200);
  })();
}

function cssSetUnderline(element, left, width) {
  return element.css({"left": left + "px", "width": width + "px"});
}

function setRandomDelay(number, range) {
  return (Math.random()) * number * range + 'ms';
}

function setOrderDelay(number, range) {
  return number * range + 'ms';
}

function setCssRandomDelay(element, indexElement, range) {
  element.css({
    "-webkit-transition-delay": setRandomDelay(indexElement, range),
    "transition-delay": setRandomDelay(indexElement, range)
  });
}

function setCssOrderDelay(element, indexElement, range) {
  element.css({
    "-webkit-transition-delay": setOrderDelay(indexElement, range),
    "transition-delay": setOrderDelay(indexElement, range)
  });
}

function onHoverAddClass(hoverElment, element, classToAdd) {
  hoverElment.hover(function () {
    element.addClass(classToAdd);
  }, function () {
    element.removeClass(classToAdd);
  })
}

/**
 * Раскрывает выпадающий блок
 * @param  {Object} path             Путь до елемента с которого всё начинается (стрелка)
 * @param  {String} parent_name      Класс родителя откуда от места начала (".exampleClass")
 * @param  {String} children_in      Класс скрытого блока (".exampleClass")
 * @param  {String | Number} duration_h  Время исполнения анимации (slow|normal|fast)
 * @param  {Boolean} hidden_path     Скрывать path элемент (true) или нет (false)
 * @param  {Number} a_time           Задаёт задержку раскрытия в милисекундах
 * @return {undefined}
 */
function wiyzjalka(path, parent_name, children_in, duration_h, hidden_path, a_time) {
  var a_time = (!a_time) ? 0 : a_time;
  path.each(function () {
    if ($(this).parents(parent_name).find(children_in).find(".js-active").length > 0 || $(this).hasClass('js-open')) {
      $(this).parents(parent_name).addClass("js-open");
      $(this).parents(parent_name).find(children_in).stop().delay(a_time).slideDown(duration_h);
      if ($(this).parents(parent_name).find(children_in).attr('aria-hidden') === "true") {
        $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'false');
      } else {
        $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'true');
      }
    }
    $(this).bind("click", function () {
      if ($(children_in).find('.hide-box').length) {
        var dMSec = (duration_h == "slow") ? 600
          : (duration_h == "normal") ? 400
            : (duration_h == "fast") ? 200 : 0;
        setTimeout(function () {
          toolboxSetClass($('.hide-box'));
        }, dMSec + a_time);
      }
      if (hidden_path) {
        $(this).hide();
      }
      path.parents(parent_name).find(children_in).stop(true, false).slideUp(duration_h);
      path.parents(parent_name).removeClass("js-open");
      path.parents(parent_name).removeClass("js-active");
      if ($(this).parents(parent_name).find(children_in).is(children_in)) {
        if ($(this).parents(parent_name).find(children_in).is(":visible")) {
          $(this).parents(parent_name).removeClass("js-open");
          $(this).parents(parent_name).find(children_in).stop().slideUp(duration_h, function () {
            // console.log($(this).css());
            $(this).css("height", "auto");
          });
          if ($(this).parents(parent_name).find(children_in).attr('aria-hidden') === "true") {
            $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'false');
          } else {
            $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'true');
          }
        } else {
          if ($(this).parents(parent_name).find(children_in).attr('aria-hidden') === "true") {
            $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'false');
          } else {
            $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'true');
          }
          $(this).parents(parent_name).addClass("js-open");
          $(this).parents(parent_name).find(children_in).stop().delay(a_time).slideDown(duration_h);
        }

      }

    });
  });
}

function setFileNameInput(element) {
  let fileInput = element.querySelector('input[type="file"]');
  fileInput.addEventListener("change", function () {
    let e = this.value;
    element.querySelector('input[type="text"]').value = '"' + e.slice(e.lastIndexOf("\\") + 1) + '"';
  });
}


function owlA11ySet(a, selector, autoTime) {

  var owlID = a.attr('id');

  // console.log(owlID);

  a.on("initialized.owl.carousel", function (e) {
    var owlCarouselItem = $('#' + owlID + ' .owl-item');
    // add listbox id's to all items
    for (i = 0; i < owlCarouselItem.length; i++) {
      $('#' + owlID + ' .owl-item').eq(i).attr("id", owlID + '-' + (i + 1));
    }
    // add accesibility properties to all items
    owlItemMakeInactive(e, (selector) ? true : false);
    owlItemMakeActive(e, (selector) ? true : false);

    setTimeout(function () {
      // console.log($('#'+owlID).children('.owl-dots'));
      $('#' + owlID).find('.owl-dots').children().each(function (a) {
        $(this).children().addClass('visually-hidden').text(a)
        // console.log($(this));
      })
    }, 0);

    $()

  });

  // after the carousel's been changed, adjust items' attributes
  a.on("translate.owl.carousel", function (e) {
    setTimeout(function () {
      owlItemMakeInactive(e, (selector) ? true : false);
      owlItemMakeActive(e, (selector) ? true : false);
    }, 100);
  });

  // add keyboard navigation to the owl carousel
  a.on('keyup', function (event) {
    // handle cursor keys
    if (event.keyCode == 37) {
      // go left
      a.trigger('prev.owl.carousel');
    } else if (event.keyCode == 39) {
      // go right
      a.trigger('next.owl.carousel');
    }
  });

  if (autoTime) {
    a.on('focusout', function () {
      a.trigger('play.owl.autoplay', [autoTime])
    });
    a.on('focusin', function () {
      a.trigger('stop.owl.autoplay')
    })
  }

}

function setAttributes(el, attrs) {
  for (var key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
}

/* Changing owl item attributes
 * set the item itself and its children to be non-tabable when inactive
 * reverse when activated
 */
function owlItemMakeInactive(e, owlActiveEl) {

  var selector = (owlActiveEl) ? '.center' : '.active',
    owlItem = e.currentTarget.querySelectorAll(".owl-item:not(" + selector + ")");
  for (var i = owlItem.length - 1; i >= 0; i--) {
    setAttributes(
      owlItem[i],
      {
        "role": "option",
        "aria-selected": "false"
      }
    );
    for (var j = owlItem[i].querySelectorAll('a').length - 1; j >= 0; j--) {
      owlItem[i].querySelectorAll('a')[j].setAttribute('tabindex', '-1');
    }
  }
}

function owlItemMakeActive(e, owlActiveEl) {

  var selector = (owlActiveEl) ? '.center' : '.active',
    owlItem = e.currentTarget.querySelectorAll(".owl-item" + selector);
  // console.log(owlActiveEl);
  for (var i = owlItem.length - 1; i >= 0; i--) {
    setAttributes(
      owlItem[i],
      {
        "role": "option",
        "aria-selected": "true"
      }
    );
    for (var j = owlItem[i].querySelectorAll('a').length - 1; j >= 0; j--) {
      owlItem[i].querySelectorAll('a')[j].setAttribute('tabindex', '0');
    }
  }
}

/* JQUERY END FUNCTION */


/*  JS FUNCTIONS  */

function getScrollbarWidth() {
  var outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.width = "100px";
  outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

  document.body.appendChild(outer);

  var widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = "scroll";

  // add innerdiv
  var inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);

  var widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
}

function isInPage(node) {
  return (node === document.body) ? false : document.body.contains(node);
}

function removeActiveClassChild(parent, cls) {
  var cls = (cls) ? cls : 'active';
  if (parent.querySelector('.' + cls)) {
    parent.querySelector('.' + cls).classList.remove(cls)
  }
}

function hasClass(element, cls) {
  return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
}

function scrollToTop(scrollDuration) {
  var scrollStep = -window.scrollY / (scrollDuration / 15),
    scrollInterval = setInterval(function () {
      if (window.scrollY != 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
}

function tabWork(element, idBox, additClass) {
  for (var i = element.length - 1; i >= 0; i--) {
    var parent = element[0].parentNode.parentNode,
      a = 'active';
    element[i].addEventListener('click', function (e) {
      onElementHeightChange(document.body, function () {
        AOS.refresh();
      });
      removeActiveClassChild(parent);
      this.classList.add(a);
      var parentBox = document.getElementById(idBox),
        listID = parentBox.querySelector(this.getAttribute("data-id"));
      removeActiveClassChild(parentBox);
      if (additClass) {
        setChildHeightToParent(listID);
      }
      try {
        listID.classList.add(a);
      } catch (s) {
        console.log(s);
      }
      if (additClass && typeof (additClass) === "object") {
        setTimeLineActive(additClass, '.' + a);
      }
      e.preventDefault();
    });
  }
}

function setChildHeightToParent(children) {
  setTimeout(function () {
    children.parentNode.style.height = children.offsetHeight + "px";
  }, 100);
}

function setTimeLineActive(element, forActive) {
  var s = element.parentNode.querySelector(forActive);
  element.style.width = (s.offsetLeft + s.offsetWidth / 2) + "px";
}

/* Работа МЕНЮ */

function hideA11yElement(e) {
  if (e.hasAttribute('aria-hidden')) {
    if (e.getAttribute('aria-hidden') === "true") {
      e.setAttribute('aria-hidden', false);
    } else {
      e.setAttribute('aria-hidden', true);
      if (window.matchMedia('(min-width: 1000px)').matches) {
        e.style.visibility = 'hidden';
        setTimeout(function () {
          e.removeAttribute("style");
        }, 1000);
      }
    }
  }
}

function miniModal(el, bodyClassToggle) {
  if (!el) {
    return false;
  }
  el.addEventListener('click', function (e) {
    var open = document.querySelectorAll('.open');
    for (var i = open.length - 1; i >= 0; i--) {
      if (!this.classList.contains('open')) {
        open[i].classList.remove('open');
        hideA11yElement(open[i]);
      }
    }
    if (!bodyClassToggle) {
      document.body.classList.remove('b-modal-open');
    }

    var href = this.dataset.id,
      box = (document.querySelector(href)) ? document.querySelector(href) : "#";
    if (!el.closest(href)) {
      box.classList.toggle('open');
      this.classList.toggle('open');

      hideA11yElement(box);

    }

    if (window.matchMedia('(min-width: 1000px)').matches) {
      setTimeout(function () {
        if (isInPage(box.querySelector("input"))) {
          box.querySelector("input").focus();
        }
      }, 100);

      box.addEventListener("blur", function (e) {
        if (!e.relatedTarget || !box.contains(e.relatedTarget)) {
          setTimeout(function () {
            if (isInPage(box.querySelector("input"))) {
              box.querySelector("input").focus();
            }
          }, 0);
        }
      }, true);
    }

    box.addEventListener('click', function (a) {
      a.stopPropagation();
    });
    if (isInPage(document.querySelector('.b-modal'))) {
      document.querySelector('.b-modal').addEventListener('click', function (a) {
        a.stopPropagation();
      });
    }

    if (!bodyClassToggle) {
      if (isInPage(document.querySelector('.open'))) {
        document.body.classList.add('b-modal-open');
      }
    }

    if (bodyClassToggle) {
      if (isInPage(document.querySelector('.open'))) {
        this.setAttribute("aria-expanded", "true");
      } else {
        this.setAttribute("aria-expanded", "false");
      }
    }

    if (isInPage(box.querySelector('.js-focus-element'))) {
      setTimeout(function () {
        box.querySelector('.js-focus-element').focus();
      }, 300);
    }

    e.stopPropagation();
    e.preventDefault();
  });

  let body = (!bodyClassToggle) ? '.b-modal-overlay' : 'html';

  document.querySelector(body).addEventListener('click', function (e) {
    var open = document.querySelectorAll('.open');
    for (var i = open.length - 1; i >= 0; i--) {
      open[i].classList.remove('open');
      hideA11yElement(open[i]);
    }
    document.body.classList.remove('b-modal-open');
  });

  document.addEventListener('keyup', function (e) {
    if (e.keyCode === 27) {
      var open = document.querySelectorAll('.open');
      for (var i = open.length - 1; i >= 0; i--) {
        open[i].classList.remove('open');
        hideA11yElement(open[i]);
      }
      document.body.classList.remove('b-modal-open');
    }
  });

}

// Wrap для каждой буквы(span)
function symbolWrap(el) {
  el.html(function (i, html) {
    return html.replace(/(\S)/g, '<span>$&</span>');
  });
  if (el.attr('data-delay')) {
    el.each(function () {
      $(this).children().not(".no-animation").each(function (a) {
        setCssOrderDelay($(this), a, +el.attr('data-delay'))
      });
    });
  }
}

/*  Сдвиг элемента при прокрутке в зависимости от места его нахождения */
function waveMove(e) {
  if (e.length) {
    // let el = e.offset().top,
    let el = $(window).height(),
      pos = (el - e.offset().top) + $(this).scrollTop()
    ;
    // console.warn(pos, el)
    if (pos < el && pos > 0) {
      let perc = Math.trunc(pos / el * 100);
      // console.log(perc);
      e.css('transform', "translateX(-" + perc / 2 + "vw)");
    }
  }
}

/*  Анимация высоты табов в bootstrap tabs  */
$(function () {
  $("a[data-toggle='tab']").on('show.bs.tab', function (e) {
    var $relatedTab = $($(e.relatedTarget).attr("href") + ".tab-pane");
    var $relatedTabContent = $relatedTab.closest(".tab-content");
    $relatedTabContent.height($relatedTab.outerHeight(true));

    var $tab = $($(e.target).attr("href") + ".tab-pane");
    var $tabContent = $tab.closest(".tab-content");
    setTimeout(function () {
      $tabContent.height($tab.outerHeight(true));
    }, 1);

    var setAutoHeight = function (evt) {
      if (evt.propertyName === "height") {
        $tabContent.css("height", "auto");
        $tabContent[0].removeEventListener("transitionend", setAutoHeight, true);
      }
    };
    $tabContent[0].addEventListener("transitionend", setAutoHeight, true);
  });
});

/* END* Работа МЕНЮ */


/**
 * Показывает ошибку для поля файл внутри формы
 * @param {Object} element — Путь до родителя элемента input[type="file"]
 * @param {String} text — Текст ошибки
 * @return {undefined}
 */
function showFileError(element, text) {
  element.insertBefore(fileError(text), element.firstChild);
}


/**
 * Проверка строки на совпадения со свойствами объекта
 * @param  {String} name — Строка для проверки
 * @param  {Object} ext — Объект для проверки
 * @return {Boolean}
 */
function checkInObject(name, ext) {
  for (let j in ext) {
    if (ext[j] === name) {
      return true;
    }
  }
  return false;
}


/**
 * Проверка файла загружаемого пользователем
 * @param  {Object} element — путь до элемента input[type="file"]
 * @param  {String} [errorClass = '.file-error'] — название класса в который обернута ошибка
 * @return {undefined}
 */
function fileCheck(element, errorClass) {

  element.addEventListener('change', function () {
    let inputFile = this.files,
      fileField = this.parentNode,
      fileForm = this.closest("form"),
      fileSelectorError = fileField.querySelectorAll((!errorClass) ? '.file-error' : errorClass),
      buttonForm = fileForm.querySelector('*[type="submit"]'),
      fileFormats = JSON.parse(this.dataset.format),
      fileSize = (this.dataset.size) ? +this.dataset.size : 1050000,
      errorFile = false;
    if (fileSelectorError) {
      for (let i = fileSelectorError.length - 1; i >= 0; i--) {
        fileField.removeChild(fileSelectorError[i]);
      }
    }
    if (inputFile.length > 3) {
      showFileError(fileField, fileField.dataset.error3);
      errorFile = true;
    } else {
      for (let i = inputFile.length - 1; i >= 0; i--) {
        let ext = getFileExt(inputFile[i].name)[0];
        if (checkInObject(ext, fileFormats)) {
          errorFile = false;
        } else {
          showFileError(fileField, fileField.dataset.error1);
          errorFile = true;
          break;
        }
        if (inputFile[i].size > fileSize) {
          showFileError(fileField, fileField.dataset.error2);
          errorFile = true;
          break;
        } else {
          errorFile = false;
        }
      }
    }
    buttonForm.disabled = errorFile !== false;
  });
}


/**
 * Возвращает расширение в названии файла
 * @param  {String} filename — название файла
 * @return {String | undefined}
 */
function getFileExt(filename) {
  return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : undefined;
}


/**
 * Обертка для вывода ошибки
 * @param  {String} text — Текст ошибки
 * @param  {String} [tag = p] — Элемент в который будет обернут текст
 * @param  {String} [atrClass = file-error] — Класс для элемента в который будет обернут текст
 * @return {HTMLElement}
 */
function fileError(text, tag, atrClass) {
  let fError = document.createElement((!tag) ? 'p' : tag);

  fError.className = (!atrClass) ? 'file-error' : atrClass;
  fError.innerHTML = text;
  return fError;
}

/*  END Проверка загружаемого файла  */


/*  *END* JS FUNCTIONS  */


